<template>
<div class="app-container">
  <!-- banner -->
  <div class="banner">
    <img  :src="bannerData.pictureUrl" class="pos-rl" v-if="bannerData.picture" :onerror="getImage(bannerData)" alt="" />
    <div class="left">
      <h2>Cases</h2>
      <div class="intro-text">
        You are here:  HomePages / Cases
      </div>
    </div>
  </div>
  <!-- cases -->
  <div class="cases columns">
    <div class="inner">
      <div class="center-header" v-if="pageData[0]">
        <h3>{{pageData[0].contentManagement.title}}</h3>
        <span>{{pageData[0].contentManagement.description}}</span>
      </div>
      <div class="band">
          <ul class="dis-flex" v-if="pageData[2]">
            <li class="brand-item" v-for="item in pageData[2].children" :key="item.id">
              <div class="band-logo">
                <!-- <img :src="item.pictureUrl" v-if="item.picture" alt=""> -->
                <img :src="item.pictureUrl" v-if="item.picture" :onerror="getImage(item,pageData[2].children)" alt="">
                <img src="../../static/brand1.jpg" v-else alt="">
              </div>
            </li>
          </ul>
      </div>
      <div class="cases-box dis-flex" v-if="pageData[1]">
        <!-- 简介 -->
        <div class="people " v-for="item in pageData[1].children" :key="item.id">
          <div class="conversation pos-rl" >
            <div class="text p16-txt ft-slim" v-html="item.contentManagement.content"></div>
          </div>
          <div class="person-box dis-flex">
            <div class="img">
              <!-- <img :src="item.pictureUrl" v-if="item.picture" alt="" /> -->
              <img :src="item.pictureUrl" v-if="item.picture" :onerror="getImage(item)" alt="">
              <img src="../../static/client.png" v-else alt="">
            </div>
            <h4>{{item.name}}</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Cookies from 'js-cookie'
import router from '@/router'
import {post, del, get, putUrl} from "@/api/http";
import request from "@/utils/request.js";

export default {
  name: 'Home',
  // components: { InfoDialog, UpdatePassword,TagsView, sideMenu},
  data() {
    return {
      pageData:[],
      bannerData:{},
      parentData:{},
    }
  },
  methods: {
    getData(){
      if(this.parentData){
        post("/publicController/publicData",{id:this.parentData.id}).then(res => {
            if (res.code == 1) {
                this.pageData=res.data.business
                this.bannerData=res.data.homeIntroduction[0]
            } else {
                this.$message.error(res.data);
            }
        });
      }
    },
    getBgStyle(item){
      if (item.picture) {
        return {
        backgroundImage:
          'url(' + item.picture + ') ',
        }
      } else {
        return {
        backgroundImage:
          'url(' +
          require('../../static/news-banner.jpg') +
          ') ',
        }
      }
    },
    // 获取图片地址
    // getImage(row,list){
    //   setTimeout(function () {
    //     if(!row.pictureUrl){
    //       get("/file/oss-url/"+row.picture).then(res=>{
    //         row.pictureUrl = res.data
    //       })
    //     }
    //   }.bind(this), 1000);
    // },
    getImage(row) {
      setTimeout(function () {
        if (!row.pictureUrl){
          request({
            url: "/file/download",
            method: "POST",
            data: {
                id:row.picture
            },
            isDownLoadFile: true,
            isParams: true,
            responseType: "blob"
          }).then(res=>{
            const blob = new Blob([res], {
                type:"application/pdf"
            });
            const linkNode = document.createElement("a");
            linkNode.style.display = "none";
            linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
            document.body.appendChild(linkNode);
            row.pictureUrl =  linkNode.href 
          })
        }
      }.bind(this), 4000);
    },
  },
  mounted() {
    this.parentData = this.$route.query
    this.getData()
    // this.navIndex = this.$route.path
    // if (Cookies.get('ClinLan') == 'en') {
    //   this.language = 'English'
    // } else {
    //   this.language = '中文'
    // }
  },
}
</script>
<style lang="scss" scoped>
.inner{
  width: 1620px;
  margin:0 auto;
}
.columns{
  padding:100px 0
}
.center-header{
  text-align: center;
    h3{
      font-size: 48px;
      line-height: 90px;
    }
    span{
      font-size: 22px;
      font-weight: 300;
    }
}

// banner
.banner{
  background-color: #F2F7FD;
  background-image: url('../../static/news-banner.jpg');
  background-size:cover;
  width: 100%;
  position: relative;
  height: 410px;
  overflow: hidden;
.left{
  position: absolute;
  left: 250px;
  width:32%;
  top:110px;
  color:#fff;

  h2{
    font-weight: 800;
    font-size: 76px;
  }
  .intro-text{
    font-size: 22px;
    font-weight: 300;
    line-height: 40px;
    margin-top:20px;
  }
}
}
.cases{
  background-image: linear-gradient(110deg, #EBF3FD, #fff,#fff,#fff);
  .cases-box{
    margin-top: 80px;
    justify-content: space-between;
  }
  .people{
    width:31%;
    .conversation{
      font-size: 16px;
      border-radius: 20px;
      height: 170px;
      line-height: 1.8;
      box-shadow: 0px 20px 27px 0px rgb( 246, 246, 246 );
      padding:37px;
      .text{
        height: 170px;
        overflow-y: auto;
      }
    }
    .conversation::before{
      content: '';
      display: block;
      position: absolute;
      width: 0;
      border: 50px solid;
      border-color: #fff transparent transparent;
      top: 244px;
      box-shadow: 0px 20px 150px 0px rgb( 246, 246, 246 );

    }
    .person-box{
      padding:60px 37px;
      height: 100px;
      h4{
        color:#1465DC;
        font-size: 18px;
        line-height: 100px;
        padding-left:10px ;
      }
    }
    .img{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      overflow: hidden;
    }
    img{
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .people:first-child .conversation::before{
    // border-color: #F6FAFE transparent transparent;
  }
  .band{
    margin:50px 0 50px;
    ul{
      flex-wrap: wrap;
    }
    .brand-item{
      width:14.2%;
      height:108px ;
      margin-bottom: 20px;
    }
    .band-logo{
      width:170px;
      height:108px;
      overflow: hidden;
      img{
      width: 100%;
      height: 100%;
      display: block;
    }
    }
  }
}
</style>
